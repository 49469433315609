import { Card, CardContent } from '@material-ui/core'
import React, { useEffect } from 'react'
import { useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { toast, ToastContainer } from 'react-toastify'

import { ConstantsContext } from '../../contexts'
import { useProfile } from '../../hooks'
import useFetchAaapDocs from '../../hooks/useFetchAaapDocs'
import { useMixpanel } from '../../hooks/useMixpanel'
import useParamsQuery from '../../hooks/useParamsQuery'
import { downloadAaapDocument, getFactSheetAvailability, getFofFactSheetAvailability, getFofQuarterFactSheetAvailability } from '../../services'
import { buildFileFromResponse } from '../../utils'
import FundDocumentListBlock from './FundDocumentListBlock'
import { notifyExceptionRaised } from './utils'
import useUpdateDocsNotification from '../../hooks/useUpdateDocsNotification'
import ReportingSection from './ReportingSection'

export default function AaapDocuments({ security_details, security_type }) {
  const { mpTrack } = useMixpanel()
  const history = useHistory()
  const urlQuery = useParamsQuery()
  const { id, name } = security_details || {}
  const profile = useProfile()
  const constants = useContext(ConstantsContext)
  const {
    fundReportingDocuments,
    isFetched,
    legalDocuments,
    presentationDocs,
    researchDocuments,
  } = useFetchAaapDocs(id, name, security_type)
  const { setNotificationToDownloaded, setNotificationToReadStatus } =
    useUpdateDocsNotification()

  const downloadDocument = async ({ id, name, story_type }) => {
    let toastId

    try {
      toastId = toast.info('We are preparing your document...', {
        autoClose: false,
        position: toast.POSITION.BOTTOM_CENTER,
      })

      const response = await downloadAaapDocument({ id })
      buildFileFromResponse(response)
      await setNotificationToReadStatus(id)
      await setNotificationToDownloaded(id)

      mpTrack({
        eventName: `Download Fund Document`,
        properties: {
          'Document Name': name,
          'Security ID': id,
          'Security Name': security_details['name'],
          Type: story_type,
        },
      })
    } catch (error) {
      const ndaRequestDetails = {
        client_name: profile['first_name'] + ' ' + profile['last_name'],
        company: profile?.['company']?.['name'],
        document_name: name,
        email: profile['email'],
        id: id,
        job_title: profile['job_title'],
        security_name: security_details['name'],
        telephone: profile['telephone'],
      }

      notifyExceptionRaised({
        context: ndaRequestDetails,
        error,
        to: constants.TECH_EMAIL_NOTIFICATION_ADDRESSES,
      })
      toast.error('Something went wrong')
    } finally {
      toast?.dismiss(toastId)
    }
  }

  useEffect(() => {
    const docIdToBeDownloaded = urlQuery.get('doc')
    const triggerDownloadDoc = async () => {
      for (const doc of [
        ...legalDocuments,
        ...researchDocuments,
        ...presentationDocs,
      ]) {
        if (String(doc?.id) === docIdToBeDownloaded) {
          await downloadDocument(doc)
          mpTrack({
            eventName: `Download Fund Document from external link`,
            properties: {
              'Document ID': doc?.id,
              'Document Type': doc?.story_type,
              Name: doc.name,
            },
          })
          break
        }
      }
      window.history.replaceState(null, '', history.location.pathname)
    }

    if (docIdToBeDownloaded && isFetched) {
      triggerDownloadDoc()
    }
  }, [
    urlQuery,
    legalDocuments,
    researchDocuments,
    presentationDocs,
    history,
    mpTrack,
    isFetched,
  ])

  return (
    <>
      <Card>
        <CardContent>
          <FundDocumentListBlock
            documents={legalDocuments}
            header="Legal Documents"
            loading={!isFetched}
            onDocumentClick={downloadDocument}
          />
          {
            security_details?.type === 'AAAP Cell' &&
            <ReportingSection
              id={id}
              name={name}
              queryKey="factsheet-availability"
              queryFn={getFactSheetAvailability}
              downloadLink={`/api/v1/aaap-securities/${id}/factsheet?as_of_date=`}
              listItemText={`${name} - Factsheet`}
              modalTitle={`Download ${name} - Factsheet`}
            />
          }
          <FundDocumentListBlock
            documents={fundReportingDocuments}
            header="Fund Reporting"
            loading={!isFetched}
            onDocumentClick={downloadDocument}
          >
            {
              security_details?.type === 'FoF Share Class' &&
              (
                <>
                  <ReportingSection
                    id={id}
                    name={name}
                    queryKey="factsheet-availability"
                    queryFn={getFofFactSheetAvailability}
                    downloadLink={`/api/v1/fof-share-classes/${id}/factsheet/?as_of_date=`}
                    listItemText={`${name} - Factsheet`}
                    modalTitle={`Download ${name} - Factsheet`}
                    isItem
                  />
                  <ReportingSection
                    id={id}
                    name={name}
                    queryKey="Quarterly-Review-availability"
                    queryFn={getFofQuarterFactSheetAvailability}
                    downloadLink={`/api/v1/fof-share-classes/${id}/quarterly-review/?review_name=`}
                    listItemText={`${name} - Quarterly Review`}
                    modalTitle={`Download ${name} - Quarterly Review`}
                    isItem
                    selectText='Select Quarterly Review'
                  />
                </>
              )
            }
          </FundDocumentListBlock>
          <FundDocumentListBlock
            documents={presentationDocs}
            header="Presentations"
            loading={!isFetched}
            onDocumentClick={downloadDocument}
          />
          <FundDocumentListBlock
            documents={researchDocuments}
            header="Research Documents"
            loading={!isFetched}
            onDocumentClick={downloadDocument}
          />
        </CardContent>
      </Card>
      <ToastContainer />
    </>
  )
}
