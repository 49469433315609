import { useQuery } from '@tanstack/react-query'
import React, { useState } from 'react'
import { Button, Group, Loader, Modal, Select } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import useDownloadPDF from '../../hooks/useDownloadPDF';
import { notifications } from '@mantine/notifications';
import { Box, ListItem, ListItemIcon, ListItemText, Typography } from '@material-ui/core';
import { StyledList } from '../myDocuments/documentList';
import { useMixpanel } from '../../hooks/useMixpanel';

interface ReportingSectionProps {
  id: string | number;
  name: string;
  queryKey: string;
  queryFn: (id: string | number) => Promise<any>;
  downloadLink: string;
  listItemText: string;
  modalTitle: string;
  children?: React.ReactNode;
  isItem?: boolean
  selectText?: string
}

const ReportingSection: React.FC<ReportingSectionProps> = ({
  id,
  name,
  queryKey,
  queryFn,
  downloadLink,
  listItemText,
  modalTitle,
  children,
  isItem,
  selectText = 'Select Date'
}) => {
  const { mpTrack } = useMixpanel()
  const [opened, { open, close }] = useDisclosure(false);
  const [value, setValue] = useState<string | null>('');
  const { handleDownload, isDownloadingPdf } = useDownloadPDF()
  const { data = [], isLoading } = useQuery(
    [queryKey, id],
    () => queryFn(id),
    {
      enabled: !!id,
      select: (res) => res?.data || []
    }
  );

  const handleClickDownload = async (url: string) => {
    try {
      await handleDownload(url)
      notifications.show({
        title: 'Downloaded successfully to your device',
        message: `${name} - ${value}`,
      })
      mpTrack({
        eventName: 'Downloaded Fund Reporting',
        properties: {
          'Fund Name': name,
          Date: value,
        }
      })
    } catch (err) {
      notifications.show({
        title: 'Download failed',
        message: 'Please try again',
      })
    }
  }

  const renderModal = () => (
    <Modal
      opened={opened}
      onClose={close}
      title={modalTitle}
      size="lg"
      withCloseButton
      centered
    >
      {
        isLoading
          ? (
            <Group justify='center'>
              <Loader />
            </Group>
          )
          : <Select
            mt={12}
            data={data}
            placeholder={data?.length > 0 ? selectText : 'No data available'}
            value={value}
            onChange={setValue}
            disabled={isLoading || data?.length === 0}
          />
      }
      <Group justify="flex-end" mt={16}>
        <Button disabled={isDownloadingPdf || !value} onClick={() => handleClickDownload(`${downloadLink}${value}`)}>
          {isDownloadingPdf ? <Loader size="sm" /> : 'Download'}
        </Button>
      </Group>
    </Modal>
  )

  const renderListItem = () => (
    <ListItem button onClick={open}>
      <ListItemIcon>
        <img alt="pdf" src="/pdf_icon.svg" style={{ objectFit: 'contain', height: 50 }} />
      </ListItemIcon>
      <ListItemText primary={listItemText} />
    </ListItem>
  )

  if (isItem) {
    return !isLoading && data?.length > 0 ? (
      <>
        {renderListItem()}
        {renderModal()}
      </>
    ) : null
  }

  return (
    <>
      <Box mb={6}>
        <Typography variant="h6" className="asset-detail-subheader">
          Fund Reporting
        </Typography>
        <StyledList>
          {renderListItem()}
          {children}
        </StyledList>
      </Box>
      {renderModal()}
    </>
  )
}

export default ReportingSection